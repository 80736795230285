import React, { useEffect, useState } from "react";
import "./GridContainer.css";
import './Card.css';
import './TodoButton.css';

const GridContainer = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("https://api.quicksell.co/v1/internal/frontend-assignment");

                if (!response.ok) {
                    throw new Error(`Error: ${response.statusText}`);
                }

                const result = await response.json();
                console.log("result 1:", result);

                setData(result);  // Save the fetched data to state
            } catch (error) {
                console.log("Something went Wrong", error);
            }
        };

        fetchData();
    }, []);

    return (
        <>

            <div className="grid-container">

                <div className="grid-container-child">

                    <div className="todo-item">
                        <input type="checkbox" />
                        <span className="todo-text">Todo</span>
                        <button className="todo-add todo-button">
                            <img src="./images/plus.png" alt="" srcset="" />
                        </button>
                        <button className="todo-more todo-button ">
                            <img src="./images/more.png" alt="" srcset="" />
                        </button>

                    </div>


                    {data?.tickets.map((currItem) => (
                        currItem.status === 'Todo' && (
                            <div className="card" key={currItem.id}>
                                <div className="card-header">
                                    <span className="card-id">{currItem.id}</span>
                                    <img
                                        src="./images/pic.JPG"
                                        alt="avatar"
                                        className="avatar"
                                    />
                                </div>

                                <h3 className="card-title">{currItem.title}</h3>
                                <div className="card-footer">
                                    <div className="status-indicator-wrapper">
                                        <div className="status-icon">
                                            <div className="icon">!</div>
                                        </div>
                                        <div className="status-indicator">
                                            <div className="dot"></div>
                                            <span className="status-text">Feature Request</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    ))}



                </div>
                <div className="grid-container-child">

                    <div className="todo-item">
                        <input type="checkbox" />
                        <span className="todo-text">In Progress</span>
                        <button className="todo-add todo-button">
                            <img src="./images/plus.png" alt="" srcset="" />
                        </button>
                        <button className="todo-more todo-button ">
                            <img src="./images/more.png" alt="" srcset="" />
                        </button>

                    </div>


                    {data?.tickets.map((currItem) => (
                        currItem.status === 'In progress' && (
                            <div className="card" key={currItem.id}>
                                <div className="card-header">
                                    <span className="card-id">{currItem.id}</span>
                                    <img
                                        src="./images/pic.JPG"
                                        alt="avatar"
                                        className="avatar"
                                    />
                                </div>

                                <h3 className="card-title">{currItem.title}</h3>
                                <div className="card-footer">
                                    <div className="status-indicator-wrapper">
                                        <div className="status-icon">
                                            <div className="icon">!</div>
                                        </div>
                                        <div className="status-indicator">
                                            <div className="dot"></div>
                                            <span className="status-text">Feature Request</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    ))}



                </div>

                <div className="grid-container-child">

                    <div className="todo-item">
                        <input type="checkbox" />
                        <span className="todo-text">Backlog</span>
                        <button className="todo-add todo-button">
                            <img src="./images/plus.png" alt="" srcset="" />
                        </button>
                        <button className="todo-more todo-button ">
                            <img src="./images/more.png" alt="" srcset="" />
                        </button>

                    </div>


                    {data?.tickets.map((currItem) => (
                        currItem.status === 'Backlog' && (
                            <div className="card" key={currItem.id}>
                                <div className="card-header">
                                    <span className="card-id">{currItem.id}</span>
                                    <img
                                        src="./images/pic.JPG"
                                        alt="avatar"
                                        className="avatar"
                                    />
                                </div>

                                <h3 className="card-title">{currItem.title}</h3>
                                <div className="card-footer">
                                    <div className="status-indicator-wrapper">
                                        <div className="status-icon">
                                            <div className="icon">!</div>
                                        </div>
                                        <div className="status-indicator">
                                            <div className="dot"></div>
                                            <span className="status-text">Feature Request</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    ))}



                </div>

                <div className="grid-container-child">

                    <div className="todo-item">
                        <input type="checkbox" />
                        <span className="todo-text">Done</span>
                        <button className="todo-add todo-button">
                            <img src="./images/plus.png" alt="" srcset="" />
                        </button>
                        <button className="todo-more todo-button ">
                            <img src="./images/more.png" alt="" srcset="" />
                        </button>

                    </div>


                    {data?.tickets.map((currItem) => (
                        currItem.status === 'Backlog' && (
                            <div className="card" key={currItem.id}>
                                <div className="card-header">
                                    <span className="card-id">{currItem.id}</span>
                                    <img
                                        src="./images/pic.JPG"
                                        alt="avatar"
                                        className="avatar"
                                    />
                                </div>

                                <h3 className="card-title">{currItem.title}</h3>
                                <div className="card-footer">
                                    <div className="status-indicator-wrapper">
                                        <div className="status-icon">
                                            <div className="icon">!</div>
                                        </div>
                                        <div className="status-indicator">
                                            <div className="dot"></div>
                                            <span className="status-text">Feature Request</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    ))}



                </div>


            </div>

        </>
    );
};

export default GridContainer;
