import './App.css';
import GridContainer from './components/HomeLayout';
import Navbar from './components/Navbar';

function App() {
  return (
   <>
   <Navbar/> 
<GridContainer/>
   </>
  );
}

export default App;
