import { useState } from "react";
import "./Navbar.css"; // Import the CSS file

const Navbar = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };


    return (
        <nav className="navbar">
            <div className="container">


                <ul className="navbar-links">
                    <li>
                        <div onClick={toggleDropdown} className="navbar-link dropdown-toggle">
                            <img src="./images/filterIcon.png" className="filterIcon" alt="" srcset="" />
                            Display
                        </div>
                        {dropdownOpen && (
                            <div className="dropdown-menu">
                                <div className="dropdown-group">
                                    <label htmlFor="grouping">Grouping</label>
                                    <select name="grouping" id="grouping">
                                        <option value="status">Status</option>
                                    </select>
                                </div>
                                <div className="dropdown-group">
                                    <label htmlFor="ordering">Ordering</label>
                                    <select name="ordering" id="ordering">
                                        <option value="priority">Priority</option>
                                    </select>
                                </div>

                            </div>
                        )}
                    </li>

                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
